import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Transhumanism",
  "date": "2014-10-21T00:00:00.000Z",
  "crossposted": ["https://www.blogger.com/blog/post/edit/preview/7233077431380052235/1364846711889874339"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`For those who may not know, transhumanism is a Utopian social movement and philosophy that looks toward a massive breakthrough in technological prowess, known as “the singularity,” that will open the door for transhumanists to “seize control of human evolution” and create a “post human species” of near immortals. Don’t roll your eyes. Transhumanists believe in their ageless post human future with a desperate passion that borders on—and often serves as a substitute for—religious faith.`}</p>
    <p>{`Transhumanism is a movement that aims to understand what makes one human, and how we can surpass our natural limitations. It believes that there is an imperative to enhance our capabilities, and that limitations to those abilities can be overcome. More importantly, it believes that technology and science are the keys to overcoming them`}</p>
    <p>{`But what are those limitations? They could be life expectancy. They could be mental acumen and intelligence. They could also be physical in nature, such as one’s strength, the speed in which one runs a race, or perhaps the way one metabolizes food.`}</p>
    <p>{` transhumanists advocate increased funding for research to radically extend healthy lifespan and favor the development of medical and technological means to improve memory, concentration, and other human capacities. Transhumanists propose that everybody should have the option to use such means to enhance various dimensions of their cognitive, emotional, and physical well-being. Not only is this a natural extension of the traditional aims of medicine and technology, but it is also a great humanitarian opportunity to genuinely improve the human condition`}</p>
    <p>{`Needless to say, transhumanists support brain implants and other measures taken to “improve” intelligence (never the ability to love, I notice)—including the ludicrous notion of uploading individual human consciousnesses into computers. And, of course, in keeping with the transhumanism’s desperate materialist yearning for a corporeal eternal life, Munkittrick says we will know we are in a transhumanist world when the average age exceeds 120.`}</p>
    <p>{`"Actions such as abortion, assisted suicide, voluntary amputation, gender reassignment, surrogate pregnancy, body modification, legal unions among adults of any number, and consenting sexual practices would be protected under law. One’s genetic make-up, neurological composition, prosthetic augmentation, and other cybernetic modifications will be limited only by technology and one’s own discretion. Transhumanism cannot happen without a legal structure that allows individuals to control their own bodies. When bodily freedom is as protected and sanctified as free speech, transhumanism will be free to develop."`}</p>
    <p>{`"In 100 years  when people have bionic legs and hands, inbuilt weapons with everyone ..i don't think that changes my mission " `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      